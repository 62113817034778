export const shadowLeftStylesVariant1 = {
    boxShadow: '-6px 0px 6px 0px rgba(0, 0, 0, 0.1)',
    clipPath: 'polygon(calc(0% - 10px) 0%, 100% 0%, 100% 100%, calc(0% - 10px) 100%)',
};

export const shadowRightStylesVariant1 = {
    boxShadow: '6px 0px 6px 0px rgba(0, 0, 0, 0.1)',
    clipPath: 'polygon(0% 0%, calc(100% + 10px) 0%, calc(100% + 10px) 100%, 0% 100%)',
};

export const shadowBottomStylesVariant1 = {
    boxShadow: '0px 6px 6px 0px rgba(0, 0, 0, 0.1)',
    clipPath: 'polygon(0% 0%, 100% 0%, 100% calc(100% + 10px), 0% calc(100% + 10px))',
};

export const shadowTopStylesVariant1 = {
    boxShadow: '0px -6px 6px 0px rgba(0, 0, 0, 0.1)',
    clipPath: 'polygon(0% calc(0% - 10px), 100% calc(0% - 10px), 100% 100%, 0% 100%)',
};
