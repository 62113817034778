import { FormDatePicker } from '@cfra-nextgen-frontend/shared/src/components/Form/FormDatePicker';
import { FormLabelWithContainer } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/FormLabelWithContainer';
import { getHookFormValidationRules } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/utils';
import { Components } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { combineIntoFormElementName, getFilterLabel, stackLabelWithContainerProps } from './shared';
import { FilterProps } from './types';

export const ScreenerFormDatePicker: React.FC<FilterProps> = ({
    control,
    filtersData,
    filterMetadataKey,
    layoutProps,
    submitHandler,
    getValues,
    useFormLabelWithContainer,
    hide,
    parentSectionKey,
    fieldDefaultStyles = {},
    uniqueIdWithinGroup,
}) => {
    const filterMetadata = filtersData.filter_metadata[filterMetadataKey];
    const filterSectionMetadata = filterMetadata.sections[parentSectionKey];
    const label = getFilterLabel(filterMetadata, parentSectionKey);
    const validationRules = getHookFormValidationRules(filtersData, filterMetadataKey, parentSectionKey);

    const filter = (
        <FormDatePicker
            name={combineIntoFormElementName({
                componentName: Components.DatePicker,
                filterMetadataKey,
                uniqueIdWithinGroup,
            })}
            control={control}
            getValues={getValues}
            submitHandler={submitHandler}
            validationRules={validationRules}
            fieldDefaultStyles={fieldDefaultStyles}
        />
    );

    if (useFormLabelWithContainer === false) {
        return filter;
    }

    return (
        <FormLabelWithContainer
            label={label}
            layoutProps={layoutProps}
            wrapperStyles={filterSectionMetadata.wrapper_styles}
            hide={hide}
            {...(filterSectionMetadata?.stack_label_and_filter && stackLabelWithContainerProps)}>
            {filter}
        </FormLabelWithContainer>
    );
};
