import { OperationTypes } from "@cfra-nextgen-frontend/shared/src/utils/enums";
import { UserPlatformManagementEntityTypes } from "@cfra-nextgen-frontend/shared/src/utils/enums";
import { FiltersData } from "../../Form/types/filters";
import { modifyFormRequestBody } from "../components/Profile/utils";

export const modifyRequestBodyFromFilterMetadata = ({
    filtersData, requestBody, entityType, operationType, entityId
}:{
    filtersData: FiltersData, 
    requestBody: Array<Record<string, any>> | Record<string, any>,
    entityType: UserPlatformManagementEntityTypes,
    operationType:OperationTypes,
    entityId: number | undefined
}) => {
    let metaData = filtersData.filter_metadata;

    Object.keys(metaData)
        .filter(
            (key) =>
                (metaData[key].dropField?.create || false) &&
                metaData[key].item_metadata.response_mapping?.default !== undefined,
        )
        .forEach((key) => {
            let fieldName = metaData[key].item_metadata.response_mapping?.default;
            if (fieldName !== undefined) {
                if (Array.isArray(requestBody)) {
                    requestBody.forEach((item) => {
                        delete item[fieldName!];
                    });
                    return;
                }
                delete requestBody[fieldName];
            }
            requestBody = modifyFormRequestBody(requestBody, entityType, operationType, entityId);
            return '';
        });
    
    if (entityType === UserPlatformManagementEntityTypes.Group && operationType === OperationTypes.CREATE) {
        if (!Array.isArray(requestBody)) {
            requestBody['account_id'] = entityId;
        }
    }
    
    if (entityType === UserPlatformManagementEntityTypes.Group_Package && operationType === OperationTypes.BULK_EDIT) {
        if (!Array.isArray(requestBody)) {
            if (requestBody[entityType].length > 0) {
                let items: Array<any> = requestBody[entityType];
                items.map((item) => {
                    item['group_id'] = entityId;
                    return '';
                });
            }
            requestBody = requestBody[entityType];
        }
    }
    
    if (
        entityType === UserPlatformManagementEntityTypes.User_Alert_Subscription &&
        operationType === OperationTypes.CREATE
    ) {
        if (requestBody['alert_type' as keyof typeof requestBody]?.length > 0) {
            const getvalue = (item: any, fieldName: string) =>
                item.key_value.startsWith(fieldName) ? Number(item.key_value.replace(`${fieldName}_|_`, '')) : null;

            let newBody = requestBody['alert_type' as keyof typeof requestBody].map((item: any) => {
                let newItem: Record<string, any> = {}

                newItem['user_id'] = entityId;
                newItem['is_active'] = true;
                newItem['delivery_type'] = 1;
                newItem['alert_type'] = getvalue(item, 'user_management.lookup_alert_type.lookup_id') || Number(item.fk_value)
                newItem['alert_subscription_package_id'] = getvalue(item,'user_management.alert_subscription_package.id')
                return newItem;
            });
            requestBody = newBody
        }
    }

    if (entityType === UserPlatformManagementEntityTypes.Package && operationType === OperationTypes.CREATE) {
        if (!Array.isArray(requestBody)) {
            if ('package_entitlement' in requestBody && requestBody['package_entitlement'].length > 0) {
                requestBody['package_entitlement'] = requestBody['package_entitlement'].map((item: any) => {
                    delete item['package_id'];
                    return item;
                });
            }
            requestBody = {
                ...requestBody,
                ...requestBody['value'],
                is_active: true,
                key: requestBody.name?.substring(0, 50) || '',
            };
            delete requestBody['value' as keyof typeof requestBody];
        }
    }

    return requestBody;
};