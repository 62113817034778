import { createTheme } from '@mui/material/styles';

const defaultBorderColor = 'rgba(0, 0, 0, 0.12)';
export const controlledAccordionsDefaultBorder = `1px solid ${defaultBorderColor}`;

export const controlledAccordionsDefaultTheme = createTheme({
    components: {
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: 'unset',
                    '&.Mui-expanded:before': {
                        opacity: 1,
                        backgroundColor: defaultBorderColor,
                    },
                    '&:last-of-type': {
                        borderRadius: 0,
                    },
                    '&:before': {
                        height: 0,
                    },
                },
            },
        },
        MuiAccordionDetails: {
            styleOverrides: {
                root: {
                    padding: 0,
                    '& > .MuiListItem-root > .MuiButtonBase-root': {
                        paddingLeft: 32,
                    },
                },
            },
        },
    },
});
