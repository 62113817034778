import { AgGridSelectedRowsContext } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridSelectedRowsContext/AgGridSelectedRowsContext';
import { ColumnDef } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/types';
import { getSnapshot, subscribe } from '@cfra-nextgen-frontend/shared/src/hooks/windowDimensions';
import { ValueTypes, formatValue } from '@cfra-nextgen-frontend/shared/src/utils/valuesFormatter';
import {
    AllCommunityModule,
    BodyScrollEvent,
    ColDef,
    Column,
    CsvExportParams,
    DefaultMenuItem,
    GetMainMenuItems,
    GridApi,
    IServerSideDatasource,
    ModelUpdatedEvent,
    ModuleRegistry,
    RowClassRules,
    RowHeightParams,
    SortChangedEvent,
    ValueFormatterParams,
    provideGlobalGridOptions,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import {
    ColumnMenuModule,
    ContextMenuModule,
    ServerSideRowModelApiModule,
    ServerSideRowModelModule,
} from 'ag-grid-enterprise';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { debounce, throttle } from 'lodash';
import {
    RefObject,
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
    useSyncExternalStore,
} from 'react';
import { isTouchDevice } from '../../utils/touchScreen';
import { AgGridDragScroll, AgGridDragScrollRef } from './AgGridDragScroll';
import { AgGridHorizontalScrollArrows, AgGridHorizontalScrollArrowsRef } from './AgGridHorizontalScrollArrows';
import './scss/AgGrid.scss';
import './scss/AgMenu.scss';
import './scss/AgPanel.scss';
import './scss/IconClasses.scss';
import './scss/Paddings.scss';
import './scss/UnsortIconStyles.scss';
import { agGridGetRenderedRowsCount, getClassNameValue, getDefaultColDef } from './utils';

// Register all community features
ModuleRegistry.registerModules([
    AllCommunityModule,
    ColumnMenuModule,
    ServerSideRowModelModule,
    ContextMenuModule,
    ServerSideRowModelApiModule,
]);

// Mark all grids as using legacy themes
provideGlobalGridOptions({ theme: 'legacy' });

export function getAgGridFormatter(formattingType: ValueTypes) {
    return function (params: ValueFormatterParams) {
        return String(
            formatValue({
                value: params.value,
                formattingType: formattingType,
            }),
        );
    };
}

export type ColumnAndGridApi = { api: GridApi };

function getColumnAndGridApiObject(eventObject?: ColumnAndGridApi, refObject?: ColumnAndGridApi) {
    if (eventObject?.api) {
        return eventObject;
    }

    return refObject;
}

export type AgGridProps = {
    columnDefs: Array<ColumnDef>;
    rowsData?: Array<any>;
    getResizableMinWidthForColumn: (key: string) => number;
    updateCustomColumnsWidths?: (agGridObject: ColumnAndGridApi) => void;
    maxNumberOfRowsToDisplay?: number;
    maxGridContainerHeightPercentage?: number; // the value in percentage from window height [1;100], needed for cases when grid container is centered, for example if grid is placed inside modal window
    useSSRMode?: boolean;
    SSRDataSource?: IServerSideDatasource;
    SSRrowsToFetch?: number;
    rowMultiSelectWithClick?: boolean;
    rowSelection?: 'singleRow' | 'multiRow';
    getRowID?: (params: any) => string;
    suppressRowClickSelection?: boolean;
    useAutoHeight?: boolean;
    gridTheme?: string | Array<string>;
    defaultRowHeight?: number;
    headerHeight?: number;
    defaultMaxWidth?: number | null;
    unlimitedCalculatedHeight?: boolean;
    rowsGap?: number;
    lastRowAdditionalWidth?: number; // needed to not cut off shadow for last row;
    horizontalScrollbarAreaHeight?: number;
    customFlexibleColumns?: Array<string>;
    getRowHeight?: (params: RowHeightParams) => number | undefined | null;
    enableAutoSizeAllColumnsRef?: RefObject<boolean>;
    onFirstDataRenderedRef?: React.MutableRefObject<() => void>;
    onModelUpdatedRef?: React.MutableRefObject<(event: ModelUpdatedEvent) => void>;
    onSortChangedRef?: React.MutableRefObject<(event: SortChangedEvent) => void>;
    fullHeightGrid?: boolean;
    embedFullWidthRows?: boolean;
    defaultCsvExportParams?: CsvExportParams;
    rowClassRules?: RowClassRules<any>;
    showSideHorizontalScrollIndicators?: boolean;
    useDragScroll?: boolean;
    useBrowserVerticalScroll?: boolean;
    onColumnMovedGetter?: (gridContainerRef: React.RefObject<HTMLDivElement>) => AgGridReactProps['onColumnMoved'];
    autoSizePadding?: number;
    tooltipShowDelay?: number;
    selectionCheckboxWidth?: number;
    checkColumnDefsEqual?: (newColumnDefs?: Array<ColDef>, currentColumnDefs?: Array<ColDef>) => boolean;
    applySortState?: boolean;
    autosizeColumnsConfig?: {
        skipHeader?: boolean;
        skipHasPinnedColumnsCheck?: boolean;
    };
    rowBuffer?: number;
    suppressHeaderMenuButton?: boolean;
    suppressHeaderFilterButton?: boolean;
    suppressHeaderContextMenu?: boolean;
    resetColumnsCallbackRef?: React.MutableRefObject<() => void>;
};

export const defaultMinWidth = 120;
export const defaultAgGirdCardRowHeight = 46;

export const AgGrid = forwardRef<AgGridReact | null, AgGridProps>((props, ref) => {
    const onModelUpdatedRefTimeout = useRef<ReturnType<typeof setTimeout> | null>(null);
    const { onRowSelected, setGridRef } = useContext(AgGridSelectedRowsContext);
    const { height } = useSyncExternalStore(subscribe, getSnapshot);
    const [gridTopPosition, setGridTopPosition] = useState<number>();
    const gridRef = useRef<AgGridReact>(null);
    const gridContainerRef = useRef<HTMLDivElement>(null);
    const setDragListenersRef = useRef<AgGridDragScrollRef>(null);
    const horizontalScrollArrowsRef = useRef<AgGridHorizontalScrollArrowsRef>(null);
    const [gridApi, setGridApi] = useState<GridApi<any>>();
    const [gridHeight, setGridHeight] = useState<number>(0);

    // just in case to avoid memory leaks
    useEffect(() => {
        return () => {
            setGridApi(undefined);
        };
    }, []);

    useEffect(() => {
        const gridContainerRefCurrent = gridContainerRef.current;
        if (gridContainerRefCurrent) {
            const { top } = gridContainerRefCurrent.getBoundingClientRect();
            setGridTopPosition(top);
        }
    }, [gridContainerRef, props]);

    const {
        rowsData = [],
        getResizableMinWidthForColumn,
        updateCustomColumnsWidths,
        maxNumberOfRowsToDisplay = 20,
        maxGridContainerHeightPercentage,
        useSSRMode = false,
        useAutoHeight = false,
        gridTheme,
        defaultRowHeight = defaultAgGirdCardRowHeight,
        headerHeight,
        defaultMaxWidth = 350,
        unlimitedCalculatedHeight,
        rowsGap,
        lastRowAdditionalWidth,
        horizontalScrollbarAreaHeight = 18,
        customFlexibleColumns,
        getRowHeight,
        enableAutoSizeAllColumnsRef,
        onFirstDataRenderedRef,
        onModelUpdatedRef,
        onSortChangedRef,
        fullHeightGrid,
        embedFullWidthRows,
        defaultCsvExportParams,
        showSideHorizontalScrollIndicators,
        useDragScroll,
        useBrowserVerticalScroll,
        onColumnMovedGetter,
        autoSizePadding,
        tooltipShowDelay = 0,
        selectionCheckboxWidth = 20,
        checkColumnDefsEqual,
        applySortState = true,
        autosizeColumnsConfig,
        rowBuffer,
        suppressHeaderMenuButton,
        suppressHeaderFilterButton,
        suppressHeaderContextMenu,
        resetColumnsCallbackRef,
    } = props;

    const _enableAutoSizeAllColumnsRef = useMemo(
        () => enableAutoSizeAllColumnsRef || { current: true },
        [enableAutoSizeAllColumnsRef],
    );

    const calculateHeight = useCallback(() => {
        const windowHeight = height || 0;

        let rowsToDisplay: number = 0;

        if (useSSRMode) {
            rowsToDisplay = props.SSRrowsToFetch || 0;
        } else {
            rowsToDisplay = fullHeightGrid && gridApi ? gridApi?.getDisplayedRowCount?.() : rowsData.length;
        }

        const numberOfRowsToShow = rowsToDisplay > maxNumberOfRowsToDisplay ? maxNumberOfRowsToDisplay : rowsToDisplay; // show vertical scroll if more than maxNumberOfRowsToDisplay rows

        const tableBodyHeight =
            horizontalScrollbarAreaHeight +
            numberOfRowsToShow * defaultRowHeight +
            (rowsGap || 0) * (numberOfRowsToShow - 1) +
            (lastRowAdditionalWidth || 0);

        const staticGridHeight = headerHeight ? tableBodyHeight + headerHeight : tableBodyHeight + defaultRowHeight;

        if (unlimitedCalculatedHeight) {
            return staticGridHeight;
        }

        const _gridTopPosition = gridTopPosition || 0;

        if (maxGridContainerHeightPercentage) {
            const maxGridContainerHeight = (windowHeight * maxGridContainerHeightPercentage) / 100;
            const gridContainerSpaceAboveTheGrid = _gridTopPosition - (windowHeight - maxGridContainerHeight) / 2;
            const maxGridHeight = maxGridContainerHeight - gridContainerSpaceAboveTheGrid;

            return staticGridHeight < maxGridHeight ? staticGridHeight : maxGridHeight;
        }

        return staticGridHeight < windowHeight - _gridTopPosition ? staticGridHeight : windowHeight - _gridTopPosition;
    }, [
        height,
        maxNumberOfRowsToDisplay,
        rowsData,
        useSSRMode,
        props.SSRrowsToFetch,
        defaultRowHeight,
        headerHeight,
        rowsGap,
        lastRowAdditionalWidth,
        horizontalScrollbarAreaHeight,
        gridTopPosition,
        maxGridContainerHeightPercentage,
        unlimitedCalculatedHeight,
        gridApi,
        fullHeightGrid,
    ]);

    const updateGridHeight = useCallback(() => {
        const calculatedHeight = calculateHeight();
        const minGridHeight =
            5 * defaultRowHeight + // 4 rows + header
            horizontalScrollbarAreaHeight;
        const gridHeight = calculatedHeight < minGridHeight ? minGridHeight : calculatedHeight;

        setGridHeight(gridHeight);
    }, [calculateHeight, defaultRowHeight, horizontalScrollbarAreaHeight]);

    useEffect(() => {
        updateGridHeight();
    }, [updateGridHeight]);

    useImperativeHandle(ref, () => gridRef.current!);

    const sortState = useMemo(
        () =>
            props.columnDefs
                .filter((colDef) => colDef.field && colDef.sort) // get only the columns that have sort enabled
                .map((colDef) => ({ colId: colDef.field!, sort: colDef.sort! })), // add sort to column state;
        [props.columnDefs],
    );

    const columnDefs = useMemo(
        () =>
            // remove excelExportDateFormat, ignoreOnExport from columnDefs, it is not using inside AgGrid
            props.columnDefs?.map(({ excelExportDateFormat, ignoreOnExport, ...restColumnDefs }) => ({
                minWidth: restColumnDefs.checkboxSelection
                    ? selectionCheckboxWidth
                    : getResizableMinWidthForColumn(String(restColumnDefs.headerName)),
                ...restColumnDefs,
                ...(applySortState ? { sort: null } : {}), // remove sort from columnDefs, to avoid grid sort issues, it will be applied separately
            })),
        [getResizableMinWidthForColumn, props.columnDefs, selectionCheckboxWidth, applySortState],
    );

    useEffect(() => {
        // Check if gridRef is available and the grid API is ready
        if (!gridRef.current || !gridRef.current.api) {
            return;
        }

        // Retrieve current column definitions
        const currentColumnDefs = gridRef.current.api.getColumnDefs();

        // Do nothing the column definitions are already set and the columns set is the same
        if (columnDefs.length === 0 || checkColumnDefsEqual?.(columnDefs, currentColumnDefs)) {
            return;
        }

        gridRef.current.api.setGridOption('columnDefs', columnDefs);
    }, [columnDefs, gridRef.current, checkColumnDefsEqual]); // keep gridRef.current in dependencies to avoid empty grid on navigate to page

    useEffect(() => {
        if (!gridRef.current || !applySortState) return;
        gridRef.current?.api?.applyColumnState({ state: sortState });
    }, [sortState, gridRef.current, applySortState]); // keep gridRef.current in dependencies to avoid empty grid on navigate to page

    const firstRenderColumnDefs = useMemo(() => columnDefs, []); // keep here no dependencies, the firstRenderColumnDefs should update only first time

    const className = useMemo(() => getClassNameValue(gridTheme), [gridTheme]);

    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo((): ColDef => {
        return {
            ...getDefaultColDef({ className, defaultMaxWidth }),
            ...(suppressHeaderMenuButton !== undefined ? { suppressHeaderMenuButton } : {}),
            ...(suppressHeaderFilterButton !== undefined ? { suppressHeaderFilterButton } : {}),
            ...(suppressHeaderContextMenu !== undefined ? { suppressHeaderContextMenu } : {}),
        };
    }, [defaultMaxWidth, className, suppressHeaderMenuButton, suppressHeaderFilterButton, suppressHeaderContextMenu]);

    function setMaxResizeWidthForAllColumnsTo(maxWidth: number, agGridObject?: ColumnAndGridApi) {
        const agGrid = agGridObject || gridRef.current;

        const columnDefsLocal: Array<ColDef> = agGrid?.api.getColumnDefs() as Array<ColDef>;
        if (!columnDefsLocal) {
            return;
        }
        agGrid?.api.setGridOption(
            'columnDefs',
            columnDefsLocal.map((columnDef) => ({ ...columnDef, maxWidth: maxWidth })),
        );
    }

    const setMinResizeWidthForAllColumns = useCallback(
        (agGridObject?: ColumnAndGridApi) => {
            const agGrid = agGridObject || gridRef.current;
            const columnDefsLocal = agGrid?.api.getColumnDefs();

            if (!columnDefsLocal) {
                return;
            }

            agGrid?.api.setGridOption(
                'columnDefs',
                columnDefsLocal.map((columnDef) => ({
                    ...columnDef,
                    minWidth: (columnDef as ColDef).checkboxSelection
                        ? selectionCheckboxWidth
                        : getResizableMinWidthForColumn(String(columnDef.headerName)),
                })),
            );
        },
        [getResizableMinWidthForColumn, selectionCheckboxWidth],
    );

    const setInitialColumnDefsForColumns = useCallback(
        ({
            keys,
            agGridObject,
            columnField,
        }: {
            keys: Array<string>;
            agGridObject?: ColumnAndGridApi;
            columnField?: string;
        }) => {
            const agGrid = agGridObject || gridRef.current;

            const localColumnDefs: Array<ColumnDef> = agGrid?.api.getColumnDefs() as Array<ColumnDef>;

            if (!localColumnDefs) {
                return;
            }

            agGrid?.api.setGridOption(
                'columnDefs',
                localColumnDefs.map((localColumnDef) => {
                    keys.forEach((key) => {
                        const colDefProp = columnDefs.filter((item) => item.field === localColumnDef.field)[0];

                        if (columnField && colDefProp.field !== columnField) {
                            return;
                        }

                        localColumnDef = {
                            ...localColumnDef,
                            [key]:
                                colDefProp && colDefProp.hasOwnProperty(key)
                                    ? colDefProp[key as keyof typeof colDefProp]
                                    : defaultColDef[key as keyof typeof defaultColDef],
                        };
                    });

                    return localColumnDef;
                }),
            );
        },
        [columnDefs, defaultColDef],
    );

    function calculateAllColumnsWidths() {
        return (
            gridRef.current?.api
                .getColumns()
                ?.reduce((prevResult, current) => (prevResult += current.getActualWidth()), 0) || 0
        );
    }

    const autoSizeAllColumns = useCallback(
        ({
            params,
            columnField,
            forceEnableAutoSizeAllColumns,
        }: {
            params: ColumnAndGridApi;
            columnField?: string;
            forceEnableAutoSizeAllColumns?: boolean;
        }) => {
            const agGridObject = params || (gridRef.current as ColumnAndGridApi);

            if (
                !agGridObject ||
                !columnDefs ||
                columnDefs.length === 0 ||
                (forceEnableAutoSizeAllColumns === undefined && !_enableAutoSizeAllColumnsRef.current) ||
                (forceEnableAutoSizeAllColumns !== undefined && !forceEnableAutoSizeAllColumns)
            ) {
                return;
            }

            const keys = ['maxWidth', 'minWidth'];

            setInitialColumnDefsForColumns({ keys, agGridObject, columnField });

            const columnIds: Array<Column> = [];
            agGridObject?.api?.getColumns()?.forEach((column) => {
                const colDef = column.getColDef();

                if (columnField && colDef?.field !== columnField) {
                    return;
                }

                if (
                    !customFlexibleColumns ||
                    !(colDef?.headerName && customFlexibleColumns.includes(colDef?.headerName))
                ) {
                    columnIds.push(column);
                    return;
                }
            });

            agGridObject?.api?.autoSizeColumns(columnIds, autosizeColumnsConfig?.skipHeader || true);

            if (!autosizeColumnsConfig?.skipHasPinnedColumnsCheck) {
                const hasPinnedColumns = agGridObject.api
                    .getColumnDefs()
                    ?.map((colDef) => (colDef as ColumnDef).pinned)
                    .some((x: any) => Boolean(x));

                if (!hasPinnedColumns) {
                    const right = agGridObject?.api?.getHorizontalPixelRange()?.right;
                    const actualWidthExceededViewableWidth = calculateAllColumnsWidths() > right;
                    if (actualWidthExceededViewableWidth) {
                        agGridObject?.api.autoSizeColumns(columnIds, false);
                    }
                }
            }

            if (updateCustomColumnsWidths) {
                updateCustomColumnsWidths(agGridObject);
            }

            setMaxResizeWidthForAllColumnsTo(9999, agGridObject); // set "unlimited" resize column width for all columns
            setMinResizeWidthForAllColumns(agGridObject);
        },
        [
            setInitialColumnDefsForColumns,
            setMinResizeWidthForAllColumns,
            updateCustomColumnsWidths,
            customFlexibleColumns,
            columnDefs,
            _enableAutoSizeAllColumnsRef,
            autosizeColumnsConfig?.skipHeader,
            autosizeColumnsConfig?.skipHasPinnedColumnsCheck,
        ],
    );

    const debouncedAutoSizeAllColumns = useMemo(() => {
        const _debouncedAutoSizeAllColumns = throttle(
            ({
                event,
                columnField,
                forceEnableAutoSizeAllColumns,
            }: {
                event?: ColumnAndGridApi;
                columnField?: string;
                forceEnableAutoSizeAllColumns?: boolean;
            }) => {
                const agGrid = getColumnAndGridApiObject(event, gridRef.current as ColumnAndGridApi);

                if (!agGrid) {
                    return;
                }

                const currentRowCount = agGridGetRenderedRowsCount(agGrid as AgGridReact); // Get the current number of displayed rows

                // don't autosize columns if no rows displayed
                if (
                    !forceEnableAutoSizeAllColumns &&
                    (currentRowCount === 1 || // should be useful for the client side rendering mode
                        // for the SSR mode, we need to wait for the first portion of data to be loaded
                        (currentRowCount === 1 && useSSRMode && props.SSRrowsToFetch && props.SSRrowsToFetch > 1))
                ) {
                    return;
                }

                autoSizeAllColumns({
                    params: agGrid,
                    columnField,
                    forceEnableAutoSizeAllColumns,
                });
            },
            500,
        );
        return _debouncedAutoSizeAllColumns;
    }, [autoSizeAllColumns, props.SSRrowsToFetch, useSSRMode]);

    useEffect(() => {
        return () => {
            debouncedAutoSizeAllColumns.cancel();
        };
    }, [debouncedAutoSizeAllColumns]);

    const onBodyScroll = useCallback(
        (event: BodyScrollEvent): void => {
            if (showSideHorizontalScrollIndicators && event.direction === 'horizontal') {
                horizontalScrollArrowsRef.current?.refreshHorizontalScrollIndicators();
                horizontalScrollArrowsRef.current?.updateArrowsVerticalPositions();
            }
        },
        [showSideHorizontalScrollIndicators],
    );

    const onColumnMoved = useMemo(() => {
        const _onColumnMoved = onColumnMovedGetter?.(gridContainerRef);
        return _onColumnMoved ? debounce(_onColumnMoved, 100) : undefined;
    }, [onColumnMovedGetter, gridContainerRef]);

    useEffect(() => {
        return () => {
            if (onColumnMoved) {
                onColumnMoved.cancel();
            }
        };
    }, [onColumnMoved]);

    const getMainMenuItems: GetMainMenuItems = useCallback(
        (params) => {
            const updatedItems = (
                [
                    'sortAscending',
                    'sortDescending',
                    'separator',
                    'autoSizeAll',
                    'separator',
                    'columnChooser',
                    'resetColumns',
                ] as Array<DefaultMenuItem>
            ).map((item) => {
                switch (item) {
                    case 'autoSizeAll':
                        return {
                            name: 'Autosize All Columns',
                            action: () =>
                                debouncedAutoSizeAllColumns({
                                    event: params,
                                    forceEnableAutoSizeAllColumns: true,
                                }),
                        };
                    case 'resetColumns':
                        return {
                            name: 'Reset Columns',
                            action: () => {
                                gridRef.current?.api.resetColumnState();
                                gridRef.current?.api.setGridOption('columnDefs', props.columnDefs);
                                gridRef.current?.api?.applyColumnState({ state: sortState });
                                resetColumnsCallbackRef?.current?.();

                                setTimeout(
                                    () =>
                                        debouncedAutoSizeAllColumns({
                                            event: params,
                                            forceEnableAutoSizeAllColumns: true,
                                        }),
                                    300,
                                );
                            },
                        };
                }
                return item;
            });
            return updatedItems;
        },
        [debouncedAutoSizeAllColumns, props.columnDefs, gridRef, resetColumnsCallbackRef, sortState],
    );

    return (
        <div ref={gridContainerRef} style={{ width: '100%' }} className='cfra-ag-grid'>
            <div
                className={className}
                style={{
                    height: useAutoHeight ? undefined : `${gridHeight}px`,
                    width: '100%',
                }}>
                <AgGridReact
                    ref={gridRef}
                    {...(useSSRMode
                        ? {
                              rowModelType: 'serverSide',
                              serverSideDatasource: props.SSRDataSource,
                              cacheBlockSize: props.SSRrowsToFetch,
                              getRowId: props.getRowID,
                              rowMultiSelectWithClick: props.rowMultiSelectWithClick,
                          }
                        : { rowData: rowsData })}
                    columnDefs={applySortState ? firstRenderColumnDefs : columnDefs}
                    defaultColDef={defaultColDef} // Default Column Properties
                    animateRows={true}
                    rowSelection={
                        props.suppressRowClickSelection
                            ? undefined
                            : {
                                  mode: props.rowSelection || 'singleRow',
                                  enableClickSelection: true,
                                  checkboxes: false,
                                  hideDisabledCheckboxes: true,
                              }
                    }
                    rowClassRules={props.rowClassRules}
                    getRowHeight={
                        getRowHeight ||
                        function (params) {
                            if (params.node.rowIndex === rowsData.length - 1) {
                                return defaultRowHeight + (lastRowAdditionalWidth || 0);
                            }
                            return defaultRowHeight + (rowsGap || 0);
                        }
                    }
                    getRowStyle={(params) => {
                        return {
                            maxHeight: getRowHeight?.(params) || defaultRowHeight,
                        };
                    }}
                    headerHeight={headerHeight || defaultRowHeight}
                    onModelUpdated={(event) => {
                        if (fullHeightGrid) {
                            updateGridHeight();
                        }

                        if (useSSRMode) {
                            // Get the current number of displayed rows
                            const currentRowCount = agGridGetRenderedRowsCount(event as unknown as AgGridReact);
                            // trigger autosize only for the first portion of data
                            if (
                                props.SSRrowsToFetch &&
                                currentRowCount > 1 &&
                                currentRowCount - 1 < props.SSRrowsToFetch
                            ) {
                                debouncedAutoSizeAllColumns({ event });
                            }
                        }

                        setDragListenersRef.current?.refreshListeners();

                        if (showSideHorizontalScrollIndicators) {
                            horizontalScrollArrowsRef.current?.refreshHorizontalScrollIndicators();
                            horizontalScrollArrowsRef.current?.updateArrowsVerticalPositions();
                        }

                        if (!onModelUpdatedRef?.current) {
                            return;
                        }

                        if (onModelUpdatedRefTimeout.current) {
                            clearTimeout(onModelUpdatedRefTimeout.current);
                        }

                        // need at least 100 timeout to have for grid rows rendered
                        onModelUpdatedRefTimeout.current = setTimeout(() => onModelUpdatedRef?.current?.(event), 100);
                    }}
                    onGridReady={(event) => {
                        debouncedAutoSizeAllColumns({ event });

                        if (fullHeightGrid) {
                            setGridApi(event.api);
                        }

                        if (!setGridRef) return;
                        setGridRef(gridRef.current);
                    }}
                    onGridSizeChanged={(event) => {
                        debouncedAutoSizeAllColumns({ event });
                    }}
                    onRowDataUpdated={(event) => {
                        debouncedAutoSizeAllColumns({ event });
                    }}
                    onGridColumnsChanged={(event) => {
                        debouncedAutoSizeAllColumns({ event });
                    }}
                    onFirstDataRendered={(event) => {
                        debouncedAutoSizeAllColumns({ event });
                        onFirstDataRenderedRef?.current?.();

                        if (showSideHorizontalScrollIndicators) {
                            horizontalScrollArrowsRef.current?.refreshHorizontalScrollIndicators();
                            horizontalScrollArrowsRef.current?.updateArrowsVerticalPositions();
                        }
                    }}
                    onColumnMoved={onColumnMoved}
                    onBodyScroll={onBodyScroll}
                    onSelectionChanged={onRowSelected || (() => {})}
                    tooltipShowDelay={tooltipShowDelay}
                    domLayout={useAutoHeight ? 'autoHeight' : 'normal'}
                    gridOptions={{
                        getContextMenuItems: function (params) {
                            var defaultItems = params.defaultItems;
                            if (!defaultItems) {
                                return [];
                            }
                            var result = defaultItems.filter((item) => item !== 'export'); // hide export option from context menu
                            return result;
                        },
                    }}
                    suppressDragLeaveHidesColumns // disable remove column by drag and drop it out of grid
                    suppressMultiSort
                    onSortChanged={(event) => {
                        onSortChangedRef?.current(event);
                    }}
                    {...(typeof embedFullWidthRows === 'boolean' ? { embedFullWidthRows: embedFullWidthRows } : {})}
                    defaultCsvExportParams={defaultCsvExportParams}
                    autoSizePadding={autoSizePadding}
                    onColumnResized={() => {
                        if (showSideHorizontalScrollIndicators) {
                            horizontalScrollArrowsRef.current?.refreshHorizontalScrollIndicators();
                        }
                    }}
                    rowBuffer={rowBuffer}
                    getMainMenuItems={getMainMenuItems}
                />
                {useDragScroll && !isTouchDevice && (
                    <AgGridDragScroll
                        gridRef={gridRef}
                        gridContainerRef={gridContainerRef}
                        ref={setDragListenersRef}
                        useBrowserVerticalScroll={useBrowserVerticalScroll}
                    />
                )}
                {showSideHorizontalScrollIndicators && (
                    <AgGridHorizontalScrollArrows
                        gridRef={gridRef}
                        gridContainerRef={gridContainerRef}
                        ref={horizontalScrollArrowsRef}
                    />
                )}
            </div>
        </div>
    );
});
