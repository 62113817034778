import { FormLabelWithContainer } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/FormLabelWithContainer';
import { getHookFormValidationRules } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/utils';
import { Components } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { useEffect } from 'react';
import { combineIntoFormElementName, getFilterLabel, stackLabelWithContainerProps } from './shared';
import { FilterProps } from './types';
import FormAutocompleteFreesolo from '@cfra-nextgen-frontend/shared/src/components/Form/FormAutocompleteFreesolo';

type ScreenerFormAutocompleteFreesoloProps = {} & FilterProps;

export const ScreenerFormAutocompleteSingleSelect: React.FC<ScreenerFormAutocompleteFreesoloProps> = ({
    control,
    filtersData,
    filterMetadataKey,
    layoutProps,
    submitHandler,
    useFormLabelWithContainer,
    hide,
    component,
    fieldDefaultStyles = {},
    parentSectionKey,
    onChangeClearHandler,
    emitDefaultValues,
}) => {
    const filterMetadata = filtersData.filter_metadata[filterMetadataKey];
    const filterSectionMetadata = filterMetadata.sections[parentSectionKey];
    const defaultValue = filterMetadata.default_value as string || ''
    const options = filtersData.data?.[filterMetadataKey].items || [];
    const validationRules = getHookFormValidationRules(filtersData, filterMetadataKey, parentSectionKey);
    const label = getFilterLabel(filterMetadata, parentSectionKey);

    useEffect(() => {
        if (defaultValue && emitDefaultValues) {
            emitDefaultValues({
                [combineIntoFormElementName({
                    componentName: component || Components.AutocompleteSingleSelect,
                    filterMetadataKey,
                })]: defaultValue,
            });
        }
    }, [defaultValue, emitDefaultValues, component, filterMetadataKey]);

    const filter = (
        <FormAutocompleteFreesolo
            control={control}
            name={combineIntoFormElementName({
                componentName: component || Components.AutocompleteSingleSelect,
                filterMetadataKey,
            })}
            validationRules={validationRules}
            options={options}
            placeholder={filterMetadata.placeholder || label}
            defaultValue={defaultValue}
            submitHandler={submitHandler}
            fieldDefaultStyles={fieldDefaultStyles}
            onChangeClearHandler={onChangeClearHandler}
            disabled={filterMetadata.disabled}
            readOnly={filterMetadata.makeReadOnlyOnDefaultValues && defaultValue ? true : false}
            forcePopupIcon={true}
            helperText={filterMetadata.caption}
        />
    );

    if (useFormLabelWithContainer === false) {
        return filter;
    }

    return (
        <FormLabelWithContainer
            label={label}
            layoutProps={layoutProps}
            wrapperStyles={filterSectionMetadata.wrapper_styles}
            hide={hide}
            {...(filterSectionMetadata?.stack_label_and_filter && stackLabelWithContainerProps)}>
            {filter}
        </FormLabelWithContainer>
    );
};