import { defaultNoResultsSymbol } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/valueFormatters';
import { ColumStyle } from '@cfra-nextgen-frontend/shared/src/components/layout/types/types';
import {
    ApplicationType,
    getDateOrUndefined,
    getMomentObjectFrom,
    getPercentageValue,
    getValueByPath,
    getValuesByPath,
    todayDateStringUS,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { ColDef, SortModelItem } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { Column, Workbook, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';
import {
    AgGridColDefExcelFormat,
    ColumnsToFormat,
    ExcelCustomFields,
    ExcelMetadata,
    ExcelTable,
    TableProps,
} from './type';
import { IMetadataFields } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { ColumnDef } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/types';

const getDisclaimerText = (applicationType?: ApplicationType): string[] => {
    switch (applicationType) {
        case ApplicationType.FUNDynamix:
            return [
                ' S&P GLOBAL™ is used under license. The owner of this trademarks is S&P Global Inc. or its affiliate, ' +
                    'which are not affiliated with CFRA Research or the author of this content. Copyright ©  2023 CFRA. All' +
                    ' rights reserved. Certain information is copyright ©2023, S&P Global Market Intelligence LLC (and its a' +
                    'ffiliates as applicable). All rights reserved. Because of the possibility of human or mechanical error,' +
                    ' any data contained herein is provided "AS IS" and CFRA does not guarantee the accuracy, adequacy, comple' +
                    'teness or availability of any information and is not responsible for any errors or omissions  or for the ' +
                    'results obtained from  the use of such information. CFRA GIVES NO EXPRESS OR IMPLIED WARRANTIES OF ANY KI' +
                    'ND, INCLUDING,  BUT NOT LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE' +
                    ' OR USE. IN NO EVENT SHALL CFRA BE LIABLE TO ANY PERSON OR ENTITY FOR ANY LIABILITY  WHATSOEVER  OR ANY D' +
                    'IRECT, INDIRECT, SPECIAL OR CONSEQUENTIAL DAMAGES IN CONNECTION WITH OR RESULTING  FROM ANY USE HEREOF. R' +
                    'EPRODUCTION OF ANY DATA CONTAINED HEREIN IN ANY FORM AND/OR DISSEMINATION,  DISTRIBUTION, RESALE OR STORA' +
                    'GE FOR SUBSEQUENT USE OF ANY SUCH PURPOSE BY ANY MEANS,  WHATSOEVER, OF ANY DATA CONTAINED HEREIN, IN WHO' +
                    'LE OR IN PART, IS STRICTLY PROHIBITED EXCEPT  WITH THE PRIOR WRITTEN PERMISSION OF CFRA.',
            ];
        case ApplicationType.InstitutionalPortal:
            return [
                'The content of this report and the opinions expressed within are those of CFRA. This analysis has not been submitted to,' + 
                'nor received approval from, the United States Securities and Exchange Commission or any other regulatory body. While CFRA ' + 
                'exercised due care in compiling this analysis, CFRA AND ALL RELATED ENTITIES SPECIFICALLY DISCLAIM ALL WARRANTIES, EXPRESS ' + 
                'OR IMPLIED, regarding the accuracy, completeness or usefulness of this information and assumes no liability with respect to ' + 
                'the consequences of relying on this information for investment or other purposes. In particular, the research provided is not ' + 
                'intended to constitute an offer, solicitation or advice to buy or sell securities.' +
                '\nCFRA may license certain intellectual property, or provide services to, or otherwise have a business relationship with, certain ' + 
                'issuers of securities that are the subject of CFRA research reports. However, no part of CFRA\'s compensation for services is tied to ' + 
                'any recommendation, rating, analysis, or commentary. Additional information on a subject company may be available upon request.' +
                '\nCFRA\'s financial data provider is S&P Global Market Intelligence. THIS DOCUMENT CONTAINS COPYRIGHTED AND TRADE SECRET MATERIAL ' + 
                'DISTRIBUTED UNDER LICENSE FROM S&P GLOBAL MARKET INTELLIGENCE. FOR RECIPIENT\'S INTERNAL USE ONLY.' +
                '\nCFRA, CFRA Accounting Lens, CFRA Legal Edge, CFRA Score, and all other CFRA product names are the trademarks, registered trademarks, ' + 
                'or service marks of CFRA or its affiliates in the United States and other jurisdictions. CFRA Score may be protected by U.S. Patent No. ' + 
                '7,974,894 and/or other patents.' +
                '\nIf you have any comments or questions, please contact cservices@cfraresearch.com.'
            ]
        default: //TODO: Keeping this as few places are calling setDisclaimer without application type, for ex - chart. we need to change that first to remove default
            return [
                ' S&P GLOBAL™ is used under license. The owner of this trademarks is S&P Global Inc. or its affiliate, ' +
                    'which are not affiliated with CFRA Research or the author of this content. Copyright ©  2023 CFRA. All' +
                    ' rights reserved. Certain information is copyright ©2023, S&P Global Market Intelligence LLC (and its a' +
                    'ffiliates as applicable). All rights reserved. Because of the possibility of human or mechanical error,' +
                    ' any data contained herein is provided "AS IS" and CFRA does not guarantee the accuracy, adequacy, comple' +
                    'teness or availability of any information and is not responsible for any errors or omissions  or for the ' +
                    'results obtained from  the use of such information. CFRA GIVES NO EXPRESS OR IMPLIED WARRANTIES OF ANY KI' +
                    'ND, INCLUDING,  BUT NOT LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE' +
                    ' OR USE. IN NO EVENT SHALL CFRA BE LIABLE TO ANY PERSON OR ENTITY FOR ANY LIABILITY  WHATSOEVER  OR ANY D' +
                    'IRECT, INDIRECT, SPECIAL OR CONSEQUENTIAL DAMAGES IN CONNECTION WITH OR RESULTING  FROM ANY USE HEREOF. R' +
                    'EPRODUCTION OF ANY DATA CONTAINED HEREIN IN ANY FORM AND/OR DISSEMINATION,  DISTRIBUTION, RESALE OR STORA' +
                    'GE FOR SUBSEQUENT USE OF ANY SUCH PURPOSE BY ANY MEANS,  WHATSOEVER, OF ANY DATA CONTAINED HEREIN, IN WHO' +
                    'LE OR IN PART, IS STRICTLY PROHIBITED EXCEPT  WITH THE PRIOR WRITTEN PERMISSION OF CFRA.',
            ];
    }
}

export const setDisclaimer = (workbook: Workbook, applicationType?: ApplicationType) => {
    let worksheet = workbook.addWorksheet('Disclaimer');

    worksheet.addRows([getDisclaimerText(applicationType)]);

    const nameCol = worksheet.getColumn('A');
    nameCol.width = 115;
    nameCol.alignment = { wrapText: true };
    return workbook;
};

function getTableColumnsProperties(columns: Array<string>) {
    return columns.map((column) => ({ name: column, filterButton: false }));
}

function getBlankRowNumber(ws: Worksheet, marginBottomRows: number = 3): number {
    return ws.rowCount + marginBottomRows;
}

function setCustomFields(ws: Worksheet, customFields: ExcelCustomFields) {
    let firstBlankRow: number = getBlankRowNumber(ws);
    for (var i = 0; i < customFields.data.length; i++) {
        let row = ws.getRow(firstBlankRow);
        for (var j = 0; j < customFields.data[i].length; j++) {
            let cell = row.getCell(j + 1);
            cell.value = customFields.data[i][j];
            if (customFields.fieldStyle && customFields.fieldStyle[i][j]) {
                if (customFields.fieldStyle[i][j].font) cell.font = customFields.fieldStyle[i][j].font!;
                if (customFields.fieldStyle[i][j].alignment) cell.alignment = customFields.fieldStyle[i][j].alignment!;
            }
        }
        firstBlankRow++;
    }
}

function getHeaderAddresses(ws: Worksheet, tableRowRef: number) {
    let headerAddresses: { [columnName: string]: string } = {};
    const row = ws.getRow(tableRowRef);

    row.eachCell({ includeEmpty: true }, function (cell) {
        headerAddresses[String(cell.value)] = cell.address.replace(/[^a-zA-Z]+/g, ''); // ignore row number
    });

    return headerAddresses;
}

function setWidth(
    column: Column,
    numberOfColumns: number,
    style?: ColumStyle,
    maxWidth: number = 155,
    autoSizeColumns: boolean = false,
) {
    if (style) {
        column.width = maxWidth * getPercentageValue(style.width);
    } else if (!autoSizeColumns) {
        column.width = maxWidth / numberOfColumns;
    }
}

function formatColumnStyle({
    ws,
    headerAddresses,
    columnsToFormat,
    columnStyles,
    autoSizeColumns,
}: {
    ws: Worksheet;
    headerAddresses: { [columnName: string]: string };
    columnsToFormat: ColumnsToFormat;
    columnStyles?: { [id: string]: ColumStyle };
    autoSizeColumns?: boolean;
}) {
    let numberOfColumns: number = Object.keys(headerAddresses).length;
    Object.entries(headerAddresses).forEach(([columnName, addr]) => {
        const column = ws.getColumn(addr);

        setWidth(column, numberOfColumns, columnStyles?.[columnName], undefined, autoSizeColumns);

        const columnToFormat = columnsToFormat[columnName];

        if (!columnToFormat) {
            return;
        }

        column.alignment = {
            horizontal:
                columnToFormat.isRightAligned ||
                (columnToFormat.numberFormat && columnToFormat.isRightAligned === undefined)
                    ? 'right'
                    : 'left',
        };

        if (!columnToFormat.numberFormat) {
            return;
        }

        column.numFmt = columnToFormat.numberFormat;
    });
}

function setDataTable(ws: Worksheet, table: ExcelTable, colRef: string = 'A', header: boolean = true): TableProps {
    const tableRowRef: number = getBlankRowNumber(ws);
    // create table
    ws.addTable({
        name: 'Table',
        ref: `${colRef}${tableRowRef}`,
        headerRow: header, // [BUG] can't be false https://github.com/exceljs/exceljs/issues/1615
        totalsRow: false,
        style: {
            theme: 'TableStyleLight1', // [BUG] can't be null
            showRowStripes: false,
        },
        columns: getTableColumnsProperties(table.allColumns), // add column properties
        rows: table.data,
    });

    let props: TableProps = {
        colRef: 'A',
        tableStart: header ? tableRowRef + 1 : tableRowRef,
        tableEnd: ws.rowCount,
        headerAddresses: header ? getHeaderAddresses(ws, tableRowRef) : {},
    };

    return props;
}

function notEmptyArray(element: any[]) {
    return element.length > 0;
}

function setMetadata(ws: Worksheet, metadata: ExcelMetadata) {
    const metadataView: any[][] = [
        [metadata.cardName],
        metadata.etfName ? ['ETF Name:', metadata.etfName] : [],
        metadata.ticker ? ['Ticker:', metadata.ticker] : [],
        ['Download Date:', todayDateStringUS()],
        metadata.asOfDate ? ['Data as of:', metadata.asOfDate] : [],
    ].filter(notEmptyArray);

    const rows = ws.addRows(metadataView);
    rows[0].getCell(1).style = { font: { bold: true } };
}

function autoSizeColumnWidths(worksheet: Worksheet) {
    worksheet.columns.forEach((column) => {
        const lengths = column.values?.map((v) => (v ? v.toString().length : 0));

        if (lengths) {
            const maxLength = Math.max(...lengths.filter((v) => typeof v === 'number'));
            column.width = Math.max(maxLength, 8); // 8 is the min size. feel free to parameterize this in the future
        }
    });
}

export const downloadXLSX = (workbook: Workbook, fileName: string) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`);
    });
};

export const getExportExcelFileName = (metadata: ExcelMetadata, fileName?: string) => {
    return fileName ? fileName : `${metadata.cardName}${metadata.ticker ? ` ${metadata.ticker}` : ''}`;
};

export type ExportExcel = (
    table: ExcelTable,
    metadata: ExcelMetadata,
    customFields?: ExcelCustomFields,
    autoSizeColumns?: boolean,
    sheetName?: string,
    fileName?: string,
) => Promise<void>;

export const getExportExcel: (logoFile: RequestInfo, applicationType: ApplicationType) => ExportExcel = (logoFile, applicationType) =>
    async function (table, metadata, customFields, autoSizeColumns, sheetName, fileName) {
        // Since the Library size is so large, we should only download when requested
        // eslint-disable-next-line
        import('exceljs').then(async (ExcelJS) => {
            // Create workbook
            const workbook = new ExcelJS.Workbook();
            // Add Data Worksheet
            const worksheet = workbook.addWorksheet(sheetName ? sheetName : metadata.cardName);

            const logoID = await fetch(logoFile)
                .then((result: Response) => result.arrayBuffer())
                .then((buffer) => workbook.addImage({ buffer: buffer, extension: 'png' }));

            worksheet.addImage(logoID, {
                tl: { col: 0, row: 0 },
                ext: { width: 130, height: 30 },
            });

            worksheet.mergeCells('A1', 'A2');
            // set metadata information
            setMetadata(worksheet, metadata);
            // create table with data
            let tableProps: TableProps = setDataTable(worksheet, table);
            // use Autowidth
            if (autoSizeColumns) {
                autoSizeColumnWidths(worksheet);
            }

            // styles for table
            formatColumnStyle({
                ws: worksheet,
                headerAddresses: tableProps.headerAddresses,
                columnsToFormat: table.columnsToFormat,
                columnStyles: table.columnStyles,
                autoSizeColumns: autoSizeColumns,
            });

            // custom logic here
            if (customFields) setCustomFields(worksheet, customFields);
            // add disclaimer sheet
            setDisclaimer(workbook, applicationType);
            // Export to Excel
            downloadXLSX(workbook, getExportExcelFileName(metadata, fileName));
        });
    };

export type AgGridExportProps = {
    fileName?: string;
    sheetName?: string;
    metadata: ExcelMetadata;
    asOfDateField?: string;
    useMaxAsOfDate?: boolean;
    autoSizeColumns?: boolean;
    getFormattedValuesFirst?: boolean;
};

export function getExportAgGrid(exportExcel: ExportExcel) {
    return function (exportRef: React.RefObject<AgGridReact> | undefined, agGridProps: AgGridExportProps, 
        fieldMetadata?: IMetadataFields[] | undefined) {
        const csvData: string[][] | undefined = exportRef?.current?.api
            ?.getDataAsCsv({
                columnSeparator: '\t',
                skipColumnHeaders: false,
                allColumns: false,
                onlySelected: false,
                suppressQuotes: true,
                processCellCallback: (cell) => {
                    if (agGridProps.getFormattedValuesFirst) {
                        return (
                            cell?.formatValue({ data: cell.node?.data, colDef: cell.column.getColDef() }) || cell?.value
                        );
                    }
                    return cell?.value || cell?.formatValue({ data: cell.node?.data, colDef: cell.column.getColDef() });
                },
            })
            ?.split('\n')
            ?.map((row) => row.split('\t')?.map((value) => value.replaceAll('\r', '')));

        if (!csvData || csvData.length === 0) {
            return;
        }

        function getAllRowData(): Array<any> {
            let rowData: Array<any> = [];
            exportRef?.current?.api?.forEachNodeAfterFilterAndSort((node) => rowData.push(node.data));
            return rowData;
        }

        const columnDefs = exportRef?.current?.api?.getColumnDefs() as Array<ColDef & AgGridColDefExcelFormat>;
        const rowData = getAllRowData();

        if (!columnDefs || columnDefs.length === 0 || !rowData || rowData.length === 0) {
            return;
        }

        // If we have an asOfDateField, figure out what it should be
        if (agGridProps.asOfDateField) {
            const getDateMomentFrom = (row: any) =>
                getMomentObjectFrom(getValueByPath(row, agGridProps.asOfDateField!));

            const dateMoment = agGridProps.useMaxAsOfDate
                ? moment.max(rowData.map((row) => getDateMomentFrom(row)))
                : getDateMomentFrom(rowData[0]);

            agGridProps.metadata.asOfDate = dateMoment.format('l');
        }

        const columnsToFormat: ColumnsToFormat = {};

        columnDefs.forEach((column) => {
            columnsToFormat[String(column.headerName)] = {
                isRightAligned: column.type === 'rightAligned',
                numberFormat: column.excelExportNumberFormat || column.excelExportDateFormat,
            };
        });

        const combinedRowData = rowData.map((singleRowData, rowIndex) => {
            const result: Array<string | number> = [];
            const currentViewColumnNames = csvData[0];

            currentViewColumnNames.forEach((currentViewColumnName, currentViewColumnNameIndex) => {
                const field = columnDefs.filter((colDef) => colDef.headerName === currentViewColumnName)[0].field;
                const dateFormat = columnDefs.filter((colDef) => colDef.headerName === currentViewColumnName)[0]
                    .excelExportDateFormat;

                if (!field) {
                    throw new Error(`No pointed field for column - ${currentViewColumnName}`);
                }

                let resultValue = undefined;

                if (columnsToFormat[currentViewColumnName] && columnsToFormat[currentViewColumnName].numberFormat) {
                    resultValue = getValueByPath(singleRowData, field);
                } else {
                    resultValue = csvData[rowIndex + 1][currentViewColumnNameIndex];
                }

                if (dateFormat) {
                    resultValue = getDateOrUndefined(resultValue); // excel js support formatting of dates only if raw value is in Date format
                }

                if (!resultValue) {
                    const metadata = fieldMetadata?.find(p => p[field])?.[field];
                    if (metadata && metadata.no_value_symbol) {
                        resultValue = metadata.no_value_symbol;
                    } else {
                        resultValue = defaultNoResultsSymbol;
                    }
                }

                result.push(resultValue);
            });

            return result;
        });

        // Create Exceltable for Export
        const excelTable: ExcelTable = {
            data: combinedRowData,
            allColumns: csvData[0],
            columnsToFormat: columnsToFormat,
            columnStyles: {},
        };

        exportExcel(
            excelTable,
            agGridProps.metadata,
            undefined,
            agGridProps.autoSizeColumns,
            agGridProps.sheetName,
            agGridProps.fileName,
        );
    };
}

export function getExportSSRAgGrid(exportExcel: ExportExcel) {
    return function (
        exportRef: React.RefObject<AgGridReact> | undefined,
        getDataFn: ((sortColumns: SortModelItem[]) => Promise<Array<any>>) | undefined,
        agGridProps: AgGridExportProps,
        fieldMetadata: IMetadataFields[] | undefined
    ) {
        if (getDataFn === undefined) return;

        let sortColumns =
            exportRef?.current?.api
                .getColumnState()
                .filter((col) => col.sort != null)
                .map((col) => {
                    return {
                        colId: col.colId,
                        sort: col.sort || 'desc',
                    } as SortModelItem;
                }) || [];

        const columnDefs = exportRef?.current?.api?.getColumnDefs() as Array<ColumnDef>;

        if (!columnDefs || columnDefs.length === 0) {
            return;
        }

        return getDataFn(sortColumns).then((rowData: Array<any>) => {
            // If we have an asOfDateField, figure out what it should be
            if (rowData.length > 0) {
                if (agGridProps.asOfDateField) {
                    const getDateMomentFrom = (row: any) =>
                        getMomentObjectFrom(getValueByPath(row, agGridProps.asOfDateField!));

                    const dateMoment = agGridProps.useMaxAsOfDate
                        ? moment.max(rowData.map((row: any) => getDateMomentFrom(row)))
                        : getDateMomentFrom(rowData[0]);

                    agGridProps.metadata.asOfDate = dateMoment.format('l');
                }

                const columnsToFormat: ColumnsToFormat = {};

                columnDefs.forEach((column) => {
                    if (column.headerName) {
                        columnsToFormat[String(column.headerName)] = {
                            isRightAligned: (column.type || column.exportType) === 'rightAligned',
                            numberFormat: column.excelExportNumberFormat || column.excelExportDateFormat,
                        };
                    }
                });

                const currentViewColumnNames = columnDefs
                    .filter((colDef) => colDef.headerName)
                    .filter((colDef) => !colDef.ignoreOnExport)
                    .map((colDef) => colDef.headerName || '');

                const combinedRowData = rowData.map((singleRowData: any, rowIndex: number) => {
                    const result: Array<string | number | Date | undefined> = [];

                    currentViewColumnNames.forEach((currentViewColumnName, currentViewColumnNameIndex) => {
                        const field = columnDefs.filter((colDef) => colDef.headerName === currentViewColumnName)[0]
                            .field;
                        const dateFormat = columnDefs.filter((colDef) => colDef.headerName === currentViewColumnName)[0]
                            .excelExportDateFormat;

                        if (!field) {
                            throw new Error(`No pointed field for column - ${currentViewColumnName}`);
                        }

                        const values = getValuesByPath(singleRowData, field);

                        let resultValue: string | number | Date | undefined =
                            values.length === 1
                                ? values[0]
                                : values
                                      .filter((item) => Boolean(item))
                                      .sort()
                                      .join(', '); // get comma separated value in case multiple values found

                        if (dateFormat) {
                            resultValue = getDateOrUndefined(resultValue); // excel js support formatting of dates only if raw value is in Date format
                        }

                        if (!resultValue) {
                            const metadata = fieldMetadata?.find(p => p[field])?.[field];
                            if (metadata && metadata.no_value_symbol) {
                                resultValue = metadata.no_value_symbol;
                            } else {
                                resultValue = defaultNoResultsSymbol;
                            }
                        }

                        result.push(resultValue);
                    });

                    return result;
                });

                // Create Exceltable for Export
                const excelTable: ExcelTable = {
                    data: combinedRowData,
                    allColumns: currentViewColumnNames,
                    columnsToFormat: columnsToFormat,
                    columnStyles: {},
                };

                exportExcel(
                    excelTable,
                    agGridProps.metadata,
                    undefined,
                    agGridProps.autoSizeColumns,
                    agGridProps.sheetName,
                    agGridProps.fileName,
                );
            }
        });
    };
}
