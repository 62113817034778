import { Components, FilterMetadata } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { fillTemplate } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/templates';
import {
    getDividerString,
    replaceAllDotsWithTabs,
    replaceAllTabsWithDots,
} from '@cfra-nextgen-frontend/shared/src/utils/strings';
import { isEqual } from 'lodash';

export function getFilterLabel(filterMetadata: FilterMetadata, parentSectionKey: string) {
    const label = filterMetadata.label !== undefined ? filterMetadata.label : filterMetadata.item_metadata.label;
    const sections = filterMetadata.sections;
    const show_asterisk = sections?.[parentSectionKey]?.show_asterisk;

    return `${label}${show_asterisk ? '*' : ''}`;
}

export const stackLabelWithContainerProps = { disableStacking: true, labelLayout: 12, containerLayout: 12 };

export const dividerString = getDividerString('DIVIDER');
const formElementNameParts = ['componentName', 'filterMetadataKey', 'uniqueIdWithinGroup'];
export const formElementNamePartsTemplate = formElementNameParts.map((item) => '{' + item + '}').join(dividerString);

type FormElementNameParts = {
    componentName: string;
    filterMetadataKey: string;
    uniqueIdWithinGroup?: string; // for cases like filters in grid column, where all the filterMetadataKey is the same, but they should have separate states
};

export function combineIntoFormElementName(props: FormElementNameParts) {
    const extendedProps = formElementNameParts.reduce(
        (accumulator, currentValue) => ({
            ...accumulator,
            [currentValue]: props[currentValue as keyof FormElementNameParts] || '',
        }),
        {} as FormElementNameParts,
    );

    extendedProps.filterMetadataKey = replaceAllDotsWithTabs(extendedProps.filterMetadataKey);

    return fillTemplate({
        templateName: 'formElementNamePartsTemplate',
        template: formElementNamePartsTemplate,
        dataObject: extendedProps,
    }) as string;
}

export function parseFormElementName(formElementName: string): FormElementNameParts {
    const parsedFormElementName = formElementName.split(dividerString).reduce((accumulator, currentValue, index) => {
        return { ...accumulator, [formElementNameParts[index]]: currentValue };
    }, {} as FormElementNameParts);

    parsedFormElementName.filterMetadataKey = replaceAllTabsWithDots(parsedFormElementName.filterMetadataKey);

    return parsedFormElementName;
}

export function getFormElementDefaultValue(formElementName: string) {
    const { componentName } = parseFormElementName(formElementName);

    switch (componentName) {
        case Components.DateRangePicker:
            return [null, null];
        case Components.Autocomplete:
        case Components.CheckboxList:
        case Components.PillsRow:
            return [];
    }
}

// formElementNamesObject is any object, which contains formElementNames as keys
export function getDefaultValues(formElementNamesObject: Record<string, any>) {
    return Object.keys(formElementNamesObject).reduce((accumulator, currentValue) => {
        return { ...accumulator, [currentValue]: getFormElementDefaultValue(currentValue) };
    }, {});
}

export function getFilteredFromDefaultValues(
    formElementNamesObject: Record<string, any>,
    emptyValues: Array<any> = [null, undefined],
) {
    return Object.keys(formElementNamesObject).reduce((accumulator, currentValue) => {
        if (
            // if value is the same as default value
            isEqual(formElementNamesObject[currentValue], getFormElementDefaultValue(currentValue)) ||
            // or if value is in empty values, then filter this value
            emptyValues.some((value) => isEqual(value, formElementNamesObject[currentValue]))
        ) {
            return accumulator;
        }

        return { ...accumulator, [currentValue]: formElementNamesObject[currentValue] };
    }, {});
}
