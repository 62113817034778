import {
    applyCustomFilterValueFormatter,
    applyDisplayFormatter,
} from '@cfra-nextgen-frontend/shared/src/components/Form/shared/utils';
import { Components, FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { FilterComponentNameToComponent } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/mappings';
import {
    ScreenerData,
    ScreenerEtfData,
    ScreenerResearchCompanyData,
    ScreenerResearchData,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import { MetadataFieldDefinition } from '@cfra-nextgen-frontend/shared/src/components/types/fieldMetadata';
import { CellRendererParam } from '@cfra-nextgen-frontend/shared/src/components/types/fieldViewData';
import { ICellRendererParams } from 'ag-grid-enterprise';
import React, { useCallback } from 'react';
import { Control, UseFormGetValues, UseFormSetValue, UseFormTrigger } from 'react-hook-form';

export const getObjectWithFilter = (cellRendererParams: Array<CellRendererParam>) =>
    cellRendererParams.find((cellRendererParam) => {
        return Object.keys(cellRendererParam).includes('filter');
    });

export function getRowLevelFilterCellRenderer({
    cellRendererParam,
    filterKeysToRowLevelFilter,
    fieldMetadata,
    screenerData,
}: {
    cellRendererParam: NonNullable<CellRendererParam>;
    filterKeysToRowLevelFilter: ReturnType<typeof getFilterKeysToRowLevelFilter>;
    fieldMetadata: MetadataFieldDefinition;
    screenerData: ScreenerEtfData | ScreenerData | ScreenerResearchData | ScreenerResearchCompanyData;
}) {
    const filter = cellRendererParam.filter;

    if (!filter) {
        throw new Error(
            `getRowLevelFilterCellRenderer exception. Filter key not found for ${JSON.stringify(cellRendererParam)}`,
        );
    }

    const RowFilter = filterKeysToRowLevelFilter[filter];
    return (props: ICellRendererParams) => {
        const calculatedValue = applyCustomFilterValueFormatter({
            rowData: props.data,
            viewdata: screenerData._viewdata,
            metadata: screenerData._metadata,
            fieldMetadata,
            returnRawCalculatedValue: true,
        });

        const disabled = applyDisplayFormatter({
            rowData: props.data,
            viewdata: screenerData._viewdata,
            metadata: screenerData._metadata,
            fieldMetadata,
        });

        return <RowFilter rowData={props.data} valueFormatted={calculatedValue} disabled={disabled} />;
    };
}

type RowLevelFilterProps = {
    rowData: Record<string, unknown | undefined>;
    valueFormatted: unknown;
    disabled?: boolean;
};

type GetFilterKeysToRowLevelFilterProps = {
    rowLevelFiltersData: FiltersData;
    parentSectionKey: string;
    getValues: UseFormGetValues<any>;
    setValue: UseFormSetValue<any>;
    trigger: UseFormTrigger<any>;
    control: Control;
    enableEdit: boolean;
    submitHandler: () => void;
};

export function getFilterKeysToRowLevelFilter({
    rowLevelFiltersData,
    parentSectionKey,
    getValues,
    setValue,
    trigger,
    control,
    enableEdit,
    submitHandler,
}: GetFilterKeysToRowLevelFilterProps): Record<string, React.FC<RowLevelFilterProps>> {
    const rowLevelFilterKeysToFilter: ReturnType<typeof getFilterKeysToRowLevelFilter> = {};

    Object.entries(rowLevelFiltersData.filter_metadata).forEach(([filterMetadataKey, filterMetadataValue]) => {
        if (filterMetadataKey in rowLevelFilterKeysToFilter) {
            throw new Error(`getRowLevelFilterKeysToFilter exception. Duplicate filter key: ${filterMetadataKey}`);
        }

        rowLevelFilterKeysToFilter[filterMetadataKey] = getRowLevelFilter({
            component: filterMetadataValue.component,
            rowLevelFiltersData,
            filterMetadataKey,
            parentSectionKey,
            control,
            getValues,
            setValue,
            trigger,
            enableEdit,
            submitHandler,
        });
    });

    return rowLevelFilterKeysToFilter;
}

const getRowLevelFilter: (
    props: { component: Components; filterMetadataKey: string } & GetFilterKeysToRowLevelFilterProps,
) => React.FC<RowLevelFilterProps> = ({
    component,
    rowLevelFiltersData,
    filterMetadataKey,
    parentSectionKey,
    control,
    getValues,
    setValue,
    trigger,
    enableEdit,
    submitHandler,
}) =>
    function (props) {
        const Filter = FilterComponentNameToComponent[enableEdit ? component : Components.StaticText];

        const validate: (fieldName: string) => Promise<boolean | undefined> = useCallback(async (fieldName: string) => {
            return await trigger(fieldName);
        }, []);

        if (!Filter) {
            throw new Error(`getRowLevelFilter exception. Filter component not found for ${component}`);
        }

        return (
            <Filter
                control={control}
                getValues={getValues}
                setValue={setValue}
                validate={validate}
                submitHandler={submitHandler}
                filtersData={rowLevelFiltersData}
                hide={false}
                filterMetadataKey={filterMetadataKey}
                parentSectionKey={parentSectionKey}
                useFormLabelWithContainer={false}
                defaultValue={props.valueFormatted}
                uniqueIdWithinGroup={props.rowData.id ? String(props.rowData.id) : ''}
                disabled={props.disabled}
            />
        );
    };

export function getRowLevelFilterFormStateKey(filterMetadataKey: string, rowId: string | number) {
    return `${filterMetadataKey}_${rowId}`;
}
