import { RoundedTextButton } from '@cfra-nextgen-frontend/shared';
import { roundedTextButtonThemeV2BorderRadius4 } from '@cfra-nextgen-frontend/shared/src/components/ETFButton/ButtonsThemes';
import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { ProjectSpecificResourcesContext } from '@cfra-nextgen-frontend/shared/src/components/ProjectSpecificResourcesContext/Context';
import { ConfirmationModal } from '@cfra-nextgen-frontend/shared/src/components/Screener/ConfirmationModal';
import {
    DirtyFields,
    RhFormData,
    getDirtyData,
    getOperateEntityRequestBody,
} from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import { OperationTypes, RequestTypes } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { UseQueryResult } from 'react-query';
import {
    cancelButtonText as CancelButtonText,
    confirmButtonText as ConfirmButtonText,
    saveButtonText as SaveButtonText,
} from './utils';

export function OperateEntityWithConfirmation({
    requestPath,
    confirmModalText,
    disableConfirmationModal,
    enableSubmitButton,
    analyticsCardName,
    dirtyFields,
    formData,
    filtersData,
    onOperationSuccess,
    onOperationFailure,
    requestType,
    saveButtonText,
    modifyRequestBody,
    sectionKey,
    requestDefaultValue,
    operationType,
    formDataState,
    modifyDeleteRequestFn,
    externalJsx,
    maxNumberOfItemsPerOneRequest,
    confirmationModalContent
}: {
    requestPath: string;
    confirmModalText?: string;
    disableConfirmationModal?: boolean;
    enableSubmitButton: boolean;
    analyticsCardName: string;
    dirtyFields: DirtyFields;
    formData?: RhFormData;
    filtersData: FiltersData;
    onOperationSuccess: (data: any) => void;
    onOperationFailure?: (error: any) => void;
    requestType: RequestTypes;
    saveButtonText?: string;
    modifyRequestBody?: (requestBody: { [key: string]: any }) => { [key: string]: any };
    modifyDeleteRequestFn?: (requestPath: string, extraParams: any) => any;
    sectionKey?: string;
    requestDefaultValue?: Record<string, any>;
    operationType?: OperationTypes;
    formDataState?: Record<string, any>;
    externalJsx?: JSX.Element;
    maxNumberOfItemsPerOneRequest?: number;
    confirmationModalContent?: React.ReactNode;
}) {
    const { operateEntity, operateMultipleEntities, userId } = useContext(ProjectSpecificResourcesContext);
    const [showConfirm, setShowConfirm] = useState(false);
    const [operationData, setOperationData] = useState<{ [key: string]: any } | undefined>();
    const [bulkOperationData, setBulkOperationData] = useState<any>([]);
    const isResponseProcessing = useRef(false);

    const operateEntityQuery = operateEntity?.({
        searchByParams: {
            path: requestPath,
            requestBody: operationData,
            config: {
                enabled: operationData !== undefined,
                cacheTime: 3000,
            },
            noErrorOnNoKeyValuePairs: true,
        },
        requestType,
        invalidate: false,
        singeRequestThreshold: maxNumberOfItemsPerOneRequest,
    }) as Array<UseQueryResult<any>> | UseQueryResult<any>;

    const operateMultipleEntitiesQuery = operateMultipleEntities?.(bulkOperationData);

    const buildCreateBuildOperationData = useCallback(
        (key: string, data: any, userId: string) => {
            let requestBody = getOperateEntityRequestBody({
                formState: { [key]: data },
                filtersData,
                userId,
                sectionKey,
                requestDefaultValue,
            });
            requestBody = modifyRequestBody?.(requestBody) || requestBody;

            if (Object.keys(requestBody).length > 0) {
                return {
                    searchByParams: {
                        path: `${requestPath}/_batch`,
                        requestBody,
                        config: {
                            enabled: true,
                            cacheTime: 3000,
                        },
                        noErrorOnNoKeyValuePairs: true,
                    },
                    requestType: RequestTypes.POST,
                    invalidate: false,
                };
            }
        },
        [filtersData, modifyRequestBody, requestDefaultValue, requestPath, sectionKey],
    );

    const isOperateResultLoaded = useMemo(() => {
        if (!operationData) {
            return false;
        }

        if (Array.isArray(operateEntityQuery)) {
            return operateEntityQuery.every(
                (query) => query && !query.isLoading && query.data && Object.keys(query.data).length > 0,
            );
        }

        return Boolean(
            operateEntityQuery &&
                !operateEntityQuery.isLoading &&
                operateEntityQuery.data &&
                Object.keys(operateEntityQuery.data).length > 0,
        );
    }, [operateEntityQuery, operationData]);

    useEffect(() => {
        if (!isResponseProcessing.current || !isOperateResultLoaded) {
            return;
        }

        isResponseProcessing.current = false;
        setOperationData(undefined);

        if (Array.isArray(operateEntityQuery)) {
            const combinedData: Array<any> = operateEntityQuery.map((query) => query.data).flat();

            if (combinedData.length === 1) {
                const data = combinedData[0];

                if (data?.isErroredResponse) {
                    setShowConfirm(false);
                    onOperationFailure?.(data);
                    return;
                }
            }

            onOperationSuccess(combinedData);
            return;
        }

        const data = operateEntityQuery.data;
        if (data?.isErroredResponse) {
            setShowConfirm(false);
            onOperationFailure?.(data);
            return;
        }

        onOperationSuccess(data);
    }, [onOperationFailure, onOperationSuccess, isOperateResultLoaded, operateEntityQuery, operationData]);

    const handleBulkEditOperation = useCallback(() => {
        let bulkOperationData: { searchByParams: SearchByParams; requestType: RequestTypes; invalidate?: boolean }[] =
            [];

        if (formDataState && userId) {
            Object.keys(formDataState).forEach((key) => {
                const dirtyStateData = formDataState[key];
                const createData = dirtyStateData[RequestTypes.POST]; //contains only the keys that have changed. [key of autocomplete]

                if (createData?.length > 0) {
                    //For 'buildCreateBuildOperationData' to build a request with values from the full set of  [key-value-count-columns in select_fields] 
                    //the 'data' parameter has to be derived from 'fromData' which contains the full set of data that changed.
                    //e.g the 'Notification' section in user profile depends on 'alert-type' column from the select-field [filter.json] when adding a  notifications
                    if (formData && Object.keys(formData).length > 0) {
                        // Extract keys from createData items
                        const keys = createData.map((item: any) => item['key']);
                        // Get dirty (modified) data from form data
                        const dirtyData = getDirtyData(dirtyFields, formData);
                        // Process each key in the dirty data
                        Object.keys(dirtyData).forEach((key) => {
                            // Filter dirty data to only include items with matching keys
                            const new_data = dirtyData[key].filter((item: any) => keys.includes(item['key']));
                            
                            // If we have matching dirty data, create and add operation request
                            if (new_data.length > 0) {
                                const requestData = buildCreateBuildOperationData(key, new_data, userId);
                                if (requestData) bulkOperationData.push(requestData);
                            }
                        });
                    } else {
                        const requestData = buildCreateBuildOperationData(key, createData, userId);
                        if (requestData) bulkOperationData.push(requestData);
                    }
                }

                const deleteData = dirtyStateData[RequestTypes.DELETE];
                if (deleteData && deleteData.length > 0) {
                    deleteData.forEach((d: any) => {
                        const deleteRequest = modifyDeleteRequestFn?.(requestPath, d);
                        bulkOperationData.push(deleteRequest);
                    });
                }
            });
            setBulkOperationData(bulkOperationData);
        } else if (formData && userId) {
            Object.keys(formData).forEach((key) => {
                const dirtyStateData = formData[key];
                if (dirtyStateData) {
                    const requestData = buildCreateBuildOperationData(key, dirtyStateData, userId);
                    if (requestData) bulkOperationData.push(requestData);
                }
            });
            setBulkOperationData(bulkOperationData);
        }

        globalThis.analytics?.registerAction?.({
            action: `click on ${ConfirmButtonText}`,
            cardName: analyticsCardName,
        });
    }, [
        analyticsCardName,
        buildCreateBuildOperationData,
        formData,
        formDataState,
        modifyDeleteRequestFn,
        requestPath,
        userId,
        dirtyFields
    ]);

    const handleConfirmClick = useCallback(() => {
        if (
            (operationType && [OperationTypes.BULK_EDIT].includes(operationType)) ||
            (requestPath === 'user_alert_subscription' && operationType === OperationTypes.CREATE)
        ) {
            handleBulkEditOperation();
        } else {
            if (formData && userId) {
                const dirtyData = getDirtyData(dirtyFields, formData);
                let requestBody = getOperateEntityRequestBody({
                    formState: dirtyData,
                    filtersData,
                    userId,
                    sectionKey,
                    requestDefaultValue,
                });
                requestBody = modifyRequestBody?.(requestBody) || requestBody;
                setOperationData(requestBody);
                isResponseProcessing.current = true;
            }
        }
        globalThis.analytics?.registerAction?.({
            action: `click on ${ConfirmButtonText}`,
            cardName: analyticsCardName,
        });
    }, [
        analyticsCardName,
        dirtyFields,
        filtersData,
        formData,
        userId,
        modifyRequestBody,
        sectionKey,
        requestDefaultValue,
        handleBulkEditOperation,
        operationType,
        requestPath
    ]);

    const isBulkEntityOperationCompleted = useMemo(
        () =>
            operateMultipleEntitiesQuery &&
            operateMultipleEntitiesQuery.length > 0 &&
            operateMultipleEntitiesQuery.every((result) => result && !result.isLoading),
        [operateMultipleEntitiesQuery],
    );

    useEffect(() => {
        if (!isBulkEntityOperationCompleted) {
            return;
        }

        const errorResponse = operateMultipleEntitiesQuery?.find((p) => p?.data?.isErroredResponse);
        if (errorResponse) {
            setBulkOperationData([]);
            onOperationFailure?.(errorResponse?.data);
            return;
        }

        let responseResult = [];
        operateMultipleEntitiesQuery?.forEach((result) => {
            if (result.data && result.data.data) {
                responseResult.push({
                    ...result.data.data,
                });
            }
        });

        setBulkOperationData([]);
        onOperationSuccess(operateMultipleEntitiesQuery?.map((p) => p.data?.data));
    }, [
        onOperationFailure,
        onOperationSuccess,
        operateMultipleEntitiesQuery,
        bulkOperationData,
        isBulkEntityOperationCompleted,
    ]);

    const saveButtonTextLocal = useMemo(() => saveButtonText || SaveButtonText, [saveButtonText]);

    const handleSaveChangesClick = useCallback(() => {
        if (!disableConfirmationModal) {
            setShowConfirm(true);
        } else {
            handleConfirmClick();
        }

        globalThis.analytics?.registerAction?.({
            action: `click on ${saveButtonTextLocal}`,
            cardName: analyticsCardName,
        });
    }, [
        analyticsCardName,
        disableConfirmationModal,
        handleConfirmClick,
        saveButtonTextLocal,
        handleBulkEditOperation,
        operationType,
    ]);

    const handleCancelClick = useCallback(() => {
        setShowConfirm(false);
        globalThis.analytics?.registerAction?.({
            action: `click on ${CancelButtonText}`,
            cardName: analyticsCardName,
        });
    }, [analyticsCardName]);

    const isLoadingData = Boolean(!isOperateResultLoaded && operationData);
    const openConfirmationModal = showConfirm && !disableConfirmationModal;
    const isLoadingDataWhenNoConfirmation = !openConfirmationModal && isLoadingData;

    return (
        <>
            {externalJsx}
            <RoundedTextButton
                type='submit'
                isLoading={isLoadingDataWhenNoConfirmation}
                theme={roundedTextButtonThemeV2BorderRadius4}
                buttonText={isLoadingDataWhenNoConfirmation ? undefined : saveButtonTextLocal}
                disabled={!enableSubmitButton || isLoadingDataWhenNoConfirmation}
                onClickCallback={handleSaveChangesClick}
            />
            <ConfirmationModal
                openModal={openConfirmationModal}
                modalText={confirmModalText || ''}
                cancelButtonText={CancelButtonText}
                confirmButtonText={ConfirmButtonText}
                cancelCallback={handleCancelClick}
                confirmCallback={handleConfirmClick}
                isLoadingConfirmation={isLoadingData}
                modalContent={confirmationModalContent}
                childrenContainerStyles={{ flexDirection: 'column'}}
            />
        </>
    );
}
