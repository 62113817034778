import { inputFontStyle } from '@cfra-nextgen-frontend/shared/src/components/Form/shared/StyledFormLabel';
import { CommonFormComponentProps } from '@cfra-nextgen-frontend/shared/src/components/Form/types/form';
import { appTheme } from '@cfra-nextgen-frontend/shared/src/components/themes/theme';
import { ThemeProvider } from '@emotion/react';
import { Theme, useMediaQuery } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { DateRange, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';
import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts';
import { Dayjs } from 'dayjs';
import { Controller } from 'react-hook-form';
import { inputBaseStyles } from './FormTextField';

const defaultTheme = createTheme(appTheme, {
    components: {
        MuiPickersLayout: {
            styleOverrides: {
                root: {
                    display: 'flex',
                },
            },
        },
        MuiPickersPopper: {
            styleOverrides: {
                root: {
                    zIndex: 3000,
                    filter: 'drop-shadow(0px 0px 7.68px rgba(0, 0, 0, 0.3))',
                },
            },
        },
        MuiStack: {
            styleOverrides: {
                root: {
                    overflow: 'visible !important',
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    maxWidth: '125px',
                },
                input: {
                    ...inputBaseStyles,
                    ...inputFontStyle,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&:not(.Mui-focused)': {
                        top: '-10px', // center inactive label
                    },
                    ...inputFontStyle,
                },
                shrink: {
                    top: '1px !important',
                },
            },
        },
    },
});

export type FormDateRangePickerProps = {
    shortcutsItems: PickersShortcutsItem<DateRange<Dayjs>>[];
    buttonsText?: {
        start?: string;
        end?: string;
    };
    theme?: Theme;
    hideCalendarMediaQuery?: string;
} & CommonFormComponentProps;

export function FormDateRangePicker({
    hideCalendarMediaQuery = '(max-width:400px)',
    ...props
}: FormDateRangePickerProps) {
    const theme = props.theme || defaultTheme;
    const hideCalendar = useMediaQuery(hideCalendarMediaQuery);
    const isBelowMd = useMediaQuery(theme.breakpoints.down('md'));
    const defaultValue = props.getValues?.(props.name) || [null, null];

    const buttonsText = props.buttonsText || { start: 'Start date', end: 'End date' };

    return (
        <Controller
            name={props.name}
            defaultValue={defaultValue}
            control={props.control}
            render={({ field }) => {
                return (
                    <ThemeProvider theme={theme}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DesktopDateRangePicker
                                {...field}
                                onChange={(event, validation) => {
                                    field.onChange(event);
                                    if (validation.validationError.filter((error) => error !== null).length === 0) {
                                        props.submitHandler?.();
                                    }
                                }}
                                localeText={buttonsText}
                                slotProps={{
                                    shortcuts: {
                                        items: props.shortcutsItems,
                                    },
                                    actionBar: { actions: [] },
                                }}
                                // TODO: if face TypeError: value.isUTC is not a function, apply getDayjsUtcDate function, as same as implemented in FormDatePicker
                                value={field.value}
                                calendars={isBelowMd ? 1 : 2}
                                disableOpenPicker={hideCalendar}
                            />
                        </LocalizationProvider>
                    </ThemeProvider>
                );
            }}
        />
    );
}
